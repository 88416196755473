.main-section {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  margin-top: $new-app-header-offset + 20px;

  .logo-centered {
    width: 400px;
    height: auto;

    &.spacing {
      margin-bottom: 26px;
      font-size: 48px;
      line-height: 56px;
    }
  }

  .main-heading {
    text-align: center;
    font-size: 48px;
    line-height: 56px;
    display: flex;
    align-items: center;
    text-align: center;

    &.spacing {
      margin-bottom: 14px;
    }
  }

  .subheading {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;    
  }

  .highlight {
    font-weight: $poppins-bold;
    color: $avo-green-1;
  }
}

@media only screen and (min-width: 2400px) {
  .main-section {
    margin-top: $new-app-header-offset + 250px !important;
    .logo-centered {
      width: 600px;
      height: auto;
    }
  }
}
@media only screen and (min-width: 2000px) {
  .main-section {
    margin-top: $new-app-header-offset + 100px;
    .logo-centered {
      width: 600px;
      height: auto;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .main-section {
    padding-top: 15px;
    margin-bottom: 20px;
    margin-top: $new-app-header-offset + 20px;
    .main-heading {
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
  
      &.spacing {
        margin-bottom: 14px;
      }
    }
    .subheading {
      font-family: 'Gilroy-Regular';
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;    
    }
    .btn-hero {
      z-index: 100;
      &.btn-connect {
        margin-top: 0;
        width: 332px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-section {
    padding-top: 15px;
    margin-bottom: 20px;
    margin-top: $new-app-header-offset + 20px;
    .main-heading {
      text-align: center;
      font-size: 32px;
      line-height: 35px;
      display: flex;
      align-items: center;
      text-align: center;
  
      &.spacing {
        margin-bottom: 14px;
      }
    }
    .subheading {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;    
    }
    .btn-hero {
      z-index: 999;
      &.btn-connect {
        margin-top: 275px;
        width: 332px;
      }
    }

    .logo-centered {
      width: 200px;
      &.spacing {
        margin-bottom: 8px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .main-section {
    //z-index: 2;
    position: absolute;
    overflow: hidden;
    padding-top: 50px;
    margin-bottom: 20px;
    margin-top: 0;
    .main-heading {
      text-align: center;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
  
      &.spacing {
        margin-bottom: 14px;
      }
    }
    .subheading {
      font-family: 'Gilroy-Regular';
      font-size: 10px;
      line-height: 10px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;    
    }
    .btn-hero {
      //z-index: 100;
      &.btn-connect {
        margin-top: 275px;
        width: 332px;
      }
    }

    .logo-centered {
      width: 200px;
      &.spacing {
        margin-bottom: 8px;
      }
    }
  }
}
