.scene {
  height: 100%;
  width: 100%;

  .sticky-main-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(22, 22, 22, 0.8);
    z-index: 110;
    .header-content {
      .mobile-header-content {
        display: none;
      }
      .btn-text {
        padding-left: 27px;
        padding-right: 27px;
      }
    }
  }

  .page-content {
    height: 100%;
  }

  .no-scroll {
    height: calc(100vh - 71px);
    overflow: hidden;
    width: 100vw;
  }

  .coming-soon {
    display: none;
  }

  .staking {
    &:hover .coming-soon {
      position: absolute;
      display: block;
      font-size: 14px;
      color: $avo-grey-2;
    }
  }

  .page-content-with-sidebar {
    position: absolute;
    min-height: 100vh;
    width: calc(100% - 270px);
    top: 0;
    left: 270px;
    background-color: $avo-black-2;
    background-image: url('../../images/dashboard-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    .main-header {
      padding-right: 40px;
      padding-bottom: 20px;
      padding-top: 30px;
      .header-white-paper {
        margin-right: 90px;
      }
    }
  }

  .toggle-navbar-btn {
    all: unset;
  }
}

@media only screen and (max-width: 1024px) {
  .scene {
    .sticky-main-header {
      .header-content {
        .full-header-content {
          display: none;
        }
        .mobile-header-content {
          display: unset;
        }
      }
    }
    .page-content-with-sidebar {
      width: 100%;
      left: 0;
      background-image: url('../../images/dashboard-bg-mobile.png');
      .main-header {
        padding-right: 79px;
        padding-bottom: 0;
      }
      .open-sidebar-overlay {
        position: fixed;
        top: $app-header-offset-tablet;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: $avo-pure-black;
        opacity: 0.8;
        z-index: 30;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .scene {
    .page-content-with-sidebar {
      .main-header {
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 5px;
      }
      .open-sidebar-overlay {
        top: $app-header-offset-mobile;
      }
    }
    .page-content {
      margin-top: 68px;
    }
  }
}
