.App {
  background-color: #0B0516;
  overflow: hidden;
}

header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 60px 12px 60px;
  z-index: 10;
  background-color: transparent;
}

@media only screen and (max-width: 480px) {
  header {
    padding: 10px 20px 10px 20px;
  }
}
