button {
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-family: $font-family-base;

  &.btn-text {
    background-color: transparent;
    color: $avo-grey-2;
    font-size: 16px;
    font-weight: $poppins-medium;
  }
  &.link {
    &::after {
      margin-left: 10px;
      content: url('../../images/icons/external-link.svg');
    }
  }
  &.dropdown {
    &::after {
      margin-left: 10px;
      content: url('../../images/icons/dropdown.svg');
    }
  }

  &.btn-text-semi-bold {
    background-color: transparent;
    color: $avo-grey-2;
    font-size: 16px;
    font-weight: $poppins-semi-bold;
  }

  &.btn-hero {
    padding: 14px 33px;
    background: linear-gradient(269.77deg, #5001b6 0.23%, #8f01ff 111.45%);
    border-radius: 8px;
    font-size: 15px;
    color: $avo-white;
    font-weight: $poppins-bold;
  }

  &.btn-inactive {
    cursor: default;
    padding: 14px 33px;
    background: $avo-grey-5;
    border-radius: 8px;
    font-size: 15px;
    color: $avo-grey-4;
    font-weight: $poppins-bold;
  }

  &.btn-header {
    display: flex;
    margin-left: 30px;
  }

  &.btn-main {
    display: none;
  }

  &.btn-discord {
    display: flex;
    align-items: center;
    &::before {
      align-self: center;
      margin-right: 12px;
      content: url('../../images/icons/discord.svg');
      margin-top: 5px;
    }
  }
  &.btn-auction {
    display: flex;
    align-items: center;
  }

  &.btn-live-auction {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(
      269.77deg,
      rgb(80, 1, 182) 0.23%,
      #8f01ff 111.45%
    );
    color: $avo-white;
    font-weight: $poppins-semi-bold;
    margin-left: 4px;
    padding: 14px 33px;
    box-sizing: border-box;
    box-shadow: 0px 4px 30px rgba(80, 1, 182, 0.6);
    border-radius: 8px;
    font-size: 16px;
  }

  &.btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: $avo-grey-2;
    font-size: 16px;
    font-weight: $poppins-medium;
    height: 24px;

    &::after {
      margin-top: 4px;
      margin-left: 12px;
      content: url('../../images/icons/arrow-right.svg');
    }
  }
  &.btn-trailer {
    border-radius: 4px;
    padding: 15px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    font-size: 16px;
    $border: 2px;
    color: $avo-white;
    font-weight: $poppins-semi-bold;
    background-clip: padding-box;
    width: 187px;
    background: rgba(24, 40, 4, 0.8);
    border: 2px solid $avo-green-1;
    box-sizing: border-box;
    box-shadow: 0px 4px 30px rgba(216, 255, 132, 0.3);
    border-radius: 8px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(269.77deg, #5001b6 0.23%, #8f01ff 111.45%);
    }
  }
}

@media only screen and (min-width: 2400px) {
  button {
    &.btn-trailer {
      font-size: 20px;
      width: 230px;
    }

    &.btn-live-auction {
      font-size: 20px;
      padding: 14px 33px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  button {
    &.btn-header {
      display: none;
    }
    &.btn-main {
      display: flex;
      z-index: 100;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 480px) {
  button {
    &.btn-trailer {
      width: 160px;
      height: 50px;
      font-size: 14px;
      box-shadow: 0px 4px 15px rgba(216, 255, 132, 0.3);
      padding: 15px 10px;
    }

    &.btn-text {
      font-size: 12px;
    }

    &.btn-inactive {
      cursor: default;
      padding: 14px 33px;
      background: $avo-grey-5;
      border-radius: 8px;
      font-size: 12px;
      color: $avo-grey-4;
      font-weight: $poppins-bold;
    }

    &.btn-auction {
      font-size: 12px;
      background-color: red;
      padding: 14px 10px;
    }

    &.btn-live-auction {
      font-size: 13px;
    }
  }
}
@media only screen and (max-width: 320px) {
  button {
    &.btn-text {
      font-size: 12px;
    }

    &.btn-inactive {
      cursor: default;
      padding: 14px 20px;
      background: $avo-grey-5;
      border-radius: 8px;
      font-size: 12px;
      color: $avo-grey-4;
      font-weight: $poppins-bold;
    }

    &.btn-auction {
      font-size: 12px;
      background-color: red;
      padding: 14px 10px;
    }

    &.btn-live-auction {
      font-size: 12px;
    }
  }
}
