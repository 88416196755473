.dropdown-menu {
  position: relative;
  display: inline-block;
  .dropdown-content {
    display: none;
    position: absolute;
    padding-top: 30px;
    background-clip: content-box;
    left: 0;
    background-color: rgba(22, 22, 22, 0.8);
    min-width: 195px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 30;
    a {
      color: $avo-grey-3;
      padding: 20px 34px;
      text-decoration: none;
      &:hover {
        background-color: $avo-pure-black;
      }
      .link-icon {
        margin-right: 10px;
      }
    }
    .show-dropdown {
      display: block;
    }
  }
  &:hover .dropdown-content {
    display: block;
  }
}
