// FONTS
// h1 -> Section Title
// h2 -> Hero Title
// headline -> Sub-Header 1

body {
  font-size: 14px;
  font-family: $font-family-base;
}

h1 {
  margin: 20px 0px;
  font-size: 50px;
  line-height: 50px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

h2 {
  margin: 0px;
  font-size: 45px;
  line-height: 45px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

h3 {
  margin: 0px;
  font-size: 42px;
  line-height: 55px;
  font-weight: $poppins-medium;
  color: $avo-grey-1;
}

h4 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 32px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

h5 {
  margin: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

.title-xl {
  margin: 20px 0px;
  font-size: 50px;
  line-height: 50px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

.title-lg {
  margin: 0px;
  font-size: 45px;
  line-height: 45px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

.title-md {
  margin: 0px;
  font-size: 42px;
  line-height: 55px;
  font-weight: $poppins-medium;
  color: $avo-grey-1;
}

.title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 32px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

.title-sm {
  margin: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: $poppins-semi-bold;
  color: $avo-white;
}

.headline-medium {
  font-size: 24px;
  line-height: 30px;
  font-weight: $poppins-regular;
  color: $avo-white;
  letter-spacing: 0.185em;
}

p {
  margin: 0;

  &.footer-link {
    font-size: 16px;
    line-height: 38px;
    font-weight: $poppins-regular;
    color: $avo-grey-footer;
    font-family: $font-family-base;
  }

  &.footer {
    font-size: 16px;
    line-height: 22px;
    font-weight: $poppins-regular;
    color: $avo-grey-footer;
    font-family: $font-family-footer;
  }

  &.footer-small {
    font-size: 10px;
    line-height: 14px;
    font-weight: $poppins-regular;
    color: $avo-grey-footer;
    font-family: $font-family-footer;
  }

  &.footer-large {
    font-size: 22px;
    line-height: 26px;
    font-weight: $poppins-medium;
    color: $avo-grey-1;
  }

  &.subtitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: $poppins-regular;
    color: $avo-grey-1;
  }

  &.body {
    font-size: 16px;
    line-height: 24px;
    font-weight: $poppins-regular;
    color: $avo-grey-2;

    span {
      font-size: inherit;
      font-weight: $poppins-medium;
      color: $avo-white;
    }
  }

  &.headline {
    font-size: 18px;
    line-height: 18px;
    font-weight: $poppins-regular;
    color: $avo-grey-2;
  }
}

@media only screen and (min-width: 2400px) {
  .title {
    font-size: 48px;
  }

  .headline-medium {
    font-size: 36px;
    line-height: 48px;
  }

  p {
    &.headline {
      font-size: 27px;
      line-height: 120%;
      font-weight: $poppins-regular;
      color: $avo-grey-2;
    }
  }
}

@media only screen and (max-width: 1024px) {
  p {
    &.headline {
      font-size: 22px;
      line-height: 120%;
      font-weight: $poppins-regular;
      color: $avo-grey-2;
    }
  }
}

@media only screen and (max-width: 480px) {
  .title-xl {
    font-size: 32px;
    line-height: 36px;
    margin-top: 0px;
  }
  .title-lg {
    font-size: 32px;
    line-height: 32px;
  }
  .title-md {
    font-size: 38px;
    line-height: 50px;
  }
  .title {
    font-size: 22px;
    line-height: 22px;
  }
  .title-sm {
    font-size: 16px;
    line-height: 24px;
  }
  .headline-medium {
    font-size: 18px;
    line-height: 30px;
  }

  p {
    &.footer-link {
      font-size: 14px;
      line-height: 36px;
      font-weight: $poppins-regular;
    }

    &.footer {
      font-size: 13px;
      line-height: 18px;
      font-weight: $poppins-regular;
    }

    &.footer-large {
      font-size: 16px;
      line-height: 24px;
      font-weight: $poppins-medium;
    }

    &.body {
      font-size: 14px;
      line-height: 21px;
    }

    &.headline {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
