.main {
  position: relative;
  width: 100vw;
  height: 100%;

  .space-placeholder {
    display: flex;
    height: 30vh;
    position: relative;
  }

  .section {
    padding: 0 190px 100px 190px;
  }

  .mobile-img {
    position: absolute;
    z-index: 2;
    left: 0;
    top: -12px;
    width: 100vw;
    object-fit: contain;
  }

  .parallax-wrap {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    min-height: 584px !important;

    .parallax-img {
      position: absolute;
      z-index: 5;
      left: 0;
      width: 100vw;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      height: calc(100vw / 1.40625);
      object-fit: cover;
      pointer-events: none;

      &.behind {
        z-index: 2;
      }
    }
  }

  .content {
    height: 2400px;
    // height: 3200px;
    position: relative;
    background-image: url("../../images/about-us-bg-7.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 4;

    &.token-sale-content {
      // height: 2200px;
      height: 2650px;
    }
  }

  .middle-section {
    background-color: #090313;
  }

  .guild-games-bg {
    z-index: -1000;
    position: absolute;
    width: 100vw;
    height: 2200px;
    align-self: flex-start;
  }

  .bottom-section {
    z-index: 5;
    position: relative;
    background-color: #0c0e0f;
  }

  .bottom-section-background {
    position: absolute;
    pointer-events: none;

    top: -500px;
    left: 0;
    width: 100%;
    object-fit: contain;
    z-index: 0;
  }

  .bottom-section-background-mobile {
    position: absolute;
    pointer-events: none;
    top: -471px;
    left: 0;
    width: 100%;
    object-fit: contain;
    z-index: 0;
  }
}

.modal-header {
  .cancel-icon {
    width: 16px;
    height: auto;
    cursor: pointer;
  }
}

.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $avo-purple-3;
  border-radius: 16px;
  cursor: pointer;

  &.spacing {
    padding: 16px;
    margin-top: 24px;
  }

  .icon {
    margin-right: 16px;
  }
}

.more-wallets {
  font-size: 14px;
  line-height: 21px;
  font-weight: $poppins-regular;
  color: $avo-grey-4;
  padding-top: 24px;
}

@media only screen and (max-width: 1200px) {
  .main {
    .content {
      // height: 2650px;

      &.token-sale-content {
        // height: 2000px;
        height: 2100px;
      }
    }

    .bottom-section-background {
      top: -300px;
    }
  }
}

@media only screen and (min-width: 1201px) {
  .main {
    .content {
      height: 2500px;
      padding-top: $new-app-header-offset;

      &.token-sale-content {
        // height: 2000px;
        height: 2450px;
      }
    }

    .bottom-section-background {
      top: -500px;
    }

    .bottom-section {
      margin-top: 0;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .main {
    .content {
      height: 2450px;
      // height: 3700px;

      &.token-sale-content {
        height: 2550px;
      }
    }

    .bottom-section-background {
      top: -550px;
    }

    .bottom-section {
      margin-top: 0;
    }
  }
}

@media only screen and (min-width: 1575px) {
  .main {
    .space-placeholder {
      display: none;
    }
  }
}

@media only screen and (min-width: 1800px) {
  .main {
    .content {
      height: 2600px;
      // height: 3700px;

      &.token-sale-content {
        height: 2800px;
      }
    }

    .bottom-section-background {
      top: -650px;
    }
  }
}

@media only screen and (min-width: 2000px) {
  .main {
    .content {
      height: 2700px;
      // height: 4000px;

      &.token-sale-content {
        // height: 2200px;
        height: 3000px;
      }
    }

    .bottom-section-background {
      top: -700px;
    }
  }
}

@media only screen and (min-width: 2300px) {
  .main {
    .content {
      height: 2900px;

      &.token-sale-content {
        height: 3100px;
      }
    }

    .bottom-section-background {
      top: -750px;
    }
  }
}

@media only screen and (min-width: 2500px) {
  .main {
    .content {
      height: 2900px;
      // height: 4100px;

      &.token-sale-content {
        // height: 2700px;
        height: 3250px;
      }
    }

    .bottom-section-background {
      top: -800px;
    }
  }
}

@media only screen and (min-width: 2750px) {
  .main {
    .content {
      &.token-sale-content {
        height: 3500px;
      }
    }

    .bottom-section-background {
      top: -850px;
    }
  }
}

@media only screen and (min-width: 3050px) {
  .main {
    .content {
      &.token-sale-content {
        height: 3650px;
      }
    }

    .bottom-section-background {
      top: -950px;
    }
  }
}

@media only screen and (min-width: 3250px) {
  .main {
    .content {
      &.token-sale-content {
        height: 3800px;
      }
    }
  }
}

@media only screen and (min-width: 3450px) {
  .main {
    .content {
      &.token-sale-content {
        height: 3950px;
      }
    }

    .bottom-section-background {
      top: -1000px;
    }
  }
}

@media only screen and (min-width: 3650px) {
  .main {
    .content {
      &.token-sale-content {
        height: 4200px;
      }
    }

    .bottom-section-background {
      top: -1100px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    .content {
      padding-top: $new-app-header-offset;
      height: 1900px;
      // height: 2400px;

      &.token-sale-content {
        // height: 2000px;
        height: 3150px;
      }
    }

    .bottom-section-background {
      top: -300px;
    }

    .bottom-section {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 834px) {
  .main {
    .parallax-wrap {
      min-height: 0 !important;
    }

    .content {
      // height: 2300px;

      &.token-sale-content {
        // height: 2200px;
        height: 3100px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    .content {
      padding-top: $new-app-header-offset;
      height: 2300px;

      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }

    .bottom-section-background {
      top: -250px;
    }

    .bottom-section {
      margin-top: 0;
      // padding-top: $new-app-header-offset;
    }

    .bottom-section-background-mobile {
      top: -610px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .main {
    padding: 0;

    .space-placeholder {
      display: none;
    }

    .content {
      padding: 0px;
      height: 1360px;
      // height: 2200px;
      background-position: 50% 500px;
      background-size: 200vw;
      // background-size: 220vw;

      &.token-sale-content {
        // height: 2880px;
        height: calc(100% + 200px);
        margin-bottom: 200px;
        background-size: 370vw;
        background-position: 50% 1000px;
      }
    }

    .section {
      padding: 0;
    }

    .guild-games-bg {
      z-index: -1000;
      position: absolute;
      width: 100vw;
      height: 1200px;
      top: 250px;
    }

    .bottom-section {
      margin-top: 0;
    }

    .bottom-section-background {
      top: -140px;
    }

    .bottom-section-background-mobile {
      position: absolute;
      pointer-events: none;
      top: -300px;
      left: 0;
      width: 100%;
      object-fit: contain;
      z-index: 0;
    }
  }
}

@media only screen and (max-width: 420px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .main {
    padding: 0;

    .space-placeholder {
      display: none;
    }

    .content {
      padding: 0px;
      height: 1360px;
      // height: 2150px;
      background-position: 50% 500px;
      background-size: 200vw;
      // background-size: 220vw;

      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
        background-size: 360vw;
        background-position: 50% 40%;
      }
    }
  }
}

@media only screen and (max-width: 411px) {
  .main {
    padding: 0;

    .space-placeholder {
      display: none;
    }

    .content {
      padding: 0px;
      height: 1300px;
      background-position: 50% 500px;

      &.token-sale-content {
        height: fit-content;
        // margin-bottom: 200px;
        margin-bottom: 35px;
        background-size: 350vw;
        background-position: 50% 40%;
      }
    }

    .section {
      padding: 0;
    }

    .guild-games-bg {
      z-index: -1000;
      position: absolute;
      width: 100vw;
      height: 1200px;
      top: 250px;
    }

    .bottom-section {
      margin-top: 0;
    }

    .bottom-section-background {
      top: -140px;
    }

    .bottom-section-background-mobile {
      position: absolute;
      pointer-events: none;
      top: -300px;
      left: 0;
      width: 100%;
      object-fit: contain;
      z-index: 0;
    }
  }
}

@media only screen and (max-width: 375px) {
  .main {
    padding: 0;

    .space-placeholder {
      display: none;
    }

    .content {
      padding: 0px;
      // height: 1300px;
      height: 1550px;
      background-size: 200vw;

      background-position: 50% 500px;
      // background-size: 220vw;

      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
        background-size: 350vw;
        background-position: 50% 40%;
      }
    }

    .section {
      padding: 0;
    }

    .guild-games-bg {
      z-index: -1000;
      position: absolute;
      width: 100vw;
      height: 1200px;
      top: 250px;
    }

    .bottom-section {
      margin-top: 0;
    }

    .bottom-section-background {
      top: -140px;
    }

    .bottom-section-background-mobile {
      position: absolute;
      pointer-events: none;
      top: -300px;
      left: 0;
      width: 100%;
      object-fit: contain;
      z-index: 0;
    }
  }
}

@media only screen and (max-width: 360px) {
  .main {
    padding: 0;

    .space-placeholder {
      display: none;
    }

    .content {
      padding: 0px;
      height: 1250px;
      // height: 1950px;
      background-position: 50% 500px;
      background-size: 200vw;
      // background-size: 210vw;

      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
        background-size: 365vw;
        background-position: 50% 40%;
      }
    }

    .section {
      padding: 0;
    }

    .guild-games-bg {
      z-index: -1000;
      position: absolute;
      width: 100vw;
      height: 1200px;
      top: 250px;
    }

    .bottom-section {
      margin-top: 0;
    }

    .bottom-section-background {
      top: -140px;
    }

    .bottom-section-background-mobile {
      position: absolute;
      pointer-events: none;
      top: -300px;
      left: 0;
      width: 100%;
      object-fit: contain;
      z-index: 0;
    }
  }
}

@media only screen and (max-width: 320px) {
  .main {
    .content {
      &.token-sale-content {
        height: fit-content;
        margin-bottom: 200px;
        background-size: 360vw;
        background-position: 50% 40%;
      }
    }
  }
}
